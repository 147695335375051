@media print {
  .widget {
    display: none;
  }

  .widgets {
    display: none;
  }

  .chordPics {
    position: absolute !important;
  }
}

@media (max-width: 768px) {
  .songArtist {
    font-size: 2rem;
  }

  .songTitle {
    font-size: 2rem;
  }
}

#capo {
  line-height: inherit;
  display: none;
}

@font-face {
  font-family: "DejaVu Sans Mono";
  src: local("DejaVu Sans Mono"),
    url("~dejavu-fonts-ttf/ttf/DejaVuSansMono.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.widget {
  padding-top: 12px;
  padding-bottom: 4px;
  padding-right: 24px;
  break-inside: avoid;
  color: var(--theme-text);
}

.widgetLabel {
  margin-right: 4px;
}

#widgets {
  padding-bottom: 16px;
}

.dark-text {
  color: black;
}

.line {
  font-family: "DejaVu Sans Mono", monospace;
  font-size: 1em;
  line-height: 1.5em;
  white-space: pre-wrap;
}

.chordLyricLine {
  font-family: "DejaVu Sans Mono", monospace;
  font-size: 1em;
  white-space: pre-wrap;
}

.fakeChordLine {
  white-space: pre-wrap;
}

.labelLine {
  font-size: 1.2em;
  font-weight: "bold";
}

.chords {
  color: var(--main-blue);
}

.chordLyricLine .chords {
  position: relative;
  height: 2.7em;
  display: inline-block;
  vertical-align: text-bottom;
}

.chordLyricLine .chords .overLyric {
  position: absolute;
}

#song {
  width: 100%;
  height: 100%;
  column-fill: auto;
}

#songContainer {
  flex: auto;
  position: relative;
}

#songView {
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 4px 12px;
}

html#songPage,
html#songPage body {
  height: 100%;
}

#allSongs {
  column-width: 360px;
  column-count: 3;
}

#chordPicBox {
  position: relative;
  padding: 20px;
  background-color: var(--theme-background);
  border: none;
}

.chordPics {
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--theme-background);
  border: 1px solid var(--main-blue);
  border-top: 0;
  border-right: 0;
  z-index: 3;
}

.hideable:hover {
  cursor: pointer;
}

.chord {
  display: inline-block;
  margin: 0 0.5em;
  vertical-align: top;
  text-align: center;
}

.chordName {
  font-size: 2vh;
}

.chordUnknown {
  font-size: 6vh;
}

.tagsLabel {
  font-size: 20px;
  padding-right: 4px;
}

.tagsContainer {
  padding-top: 8px;
  padding-bottom: 8px;
}

.songArtist {
  font-weight: 500;
  padding-bottom: 4px;
}

.songTitle {
  padding-bottom: 8px;
}

.songTag {
  break-inside: avoid-column;
  padding-top: 16px;
  padding-bottom: 16px;
}
