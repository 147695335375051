/*
Note: always import bootstrap first, or else it'll overwrite styles
*/
@import "~bootstrap/dist/css/bootstrap.css";

:root {
  --dark-blue: #337ab7;
  --main-blue: var(--dark-blue);
  --theme-text: black;
  --theme-background: white;
}

@media screen and (max-device-width: 600px) {
  #allSongs {
    column-count: 1;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --main-blue: #a2c6e5;
    --theme-text: white;
    --theme-background: black;
  }

  svg {
    filter: invert(100%);
  }
}

html body {
  background: var(--theme-background);
  color: var(--theme-text);
}

pre {
  border: 0;
  background-color: transparent;
  font-family: "DejaVu Sans Mono", monospace;
}

/* Links */

a {
  color: var(--main-blue);
}

a:hover {
  text-decoration: underline;
  color: var(--main-blue);
}

a.songLink {
  color: var(--theme-text);
}

a.songLink:hover {
  color: var(--theme-text);
}

/* Navbar styles */

.navbar-light .navbar-nav .nav-link {
  color: var(--dark-blue);
}

.navbar-light .navbar-nav .nav-link:hover {
  font-weight: 500;
  text-decoration: none;
  color: var(--dark-blue);
}

.main-nav-item {
  padding-right: 8px;
}

/* Navbar social icons */

.social-section {
  margin-left: auto;
}

.social-image {
  padding: 0 4px;
  font-size: 24px;
  color: black;
}

.social-image:hover {
  opacity: 0.5;
}

/* Container */

.container {
  padding: 16px 0;
  max-width: 800px;
}

.formContainer {
  max-width: 500px;
}

.textContainer {
  max-width: 600px;
}

/* Form fields */

.label {
  margin-bottom: 4px;
}

.hidden {
  display: none;
}

.formGroup {
  margin-bottom: 24px;
}

.formField {
  margin-bottom: 12px;
}

.formButton {
  margin-top: 8px;
  margin-right: 16px;
}

/* Text */

.headingPrimary {
  font-size: 28px;
  font-weight: 600;
}

.headingSecondary {
  font-size: 20px;
  font-weight: 600;
}

.songViewTitle {
  margin-bottom: 4px;
}

.headingTertiary {
  font-size: 16px;
}
